import React from 'react';
import Layout from '../components/Layout';
import Heading from '../components/Heading';

export default function Telehealth() {
  return (
    <Layout>
        <Heading>
        Telehealth
        </Heading>
        {/* <h2>Dermatology Consultants Teledermatology Policy</h2> */}
        <h3>What is Telehealth/Telemedicine?</h3>
        <p>Telehealth or telemedicine is a service that allows a patient medical care via digital systems. </p>
        <p>The Health Resources Services Administration defines telehealth as the use of electronic information and telecommunications technologies to support long-distance clinical health care, 
            patient and professional health-related education, public health and health administration. Technologies include videoconferencing, the internet, store-and-forward imaging, streaming media, 
            and terrestrial and wireless communications.</p>
            {/* above source: https://www.healthit.gov/faq/what-telehealth-how-telehealth-different-telemedicine */}
        <h3>Use cases for Telehealth</h3>    
        <p>We offer Telehealth services to be able to accommodate patients who are unable to make a traditional office visit.</p>
        {/* Patients can have access to providers utilizing video conference calls. */}
        <p>Examples being:</p>
        <ul>
            <li>Individuals that live in rural areas</li>
            <li>Individuals having difficulty with access to reliable transportation</li>
            <li>Urgent visits and consultations (patients may need to speak to a provider for post-surgery care)</li>
            <li>Individuals with a compromised immune system or are under quarantine</li>
        </ul>
        <h3>How we use Telemedicine</h3>
        <p>We primarily use real-time video conferencing for a telehealth visit. All of the products/services we use to conduct a telehealth visit are HIPAA compliant. 
            We have Business Associate Agreements (BAAs) with all services that handle patient information. Our providers have set guidelines for our staff to know the situations when telehealth is appropriate. 
            Not all diseases or conditions are appropriate for telehealth, so the office will have discretion to issue a telehealth visit.</p>
    </Layout>
  );
}
